export const formatDate = date => {
  if (!date || date.getUTCFullYear() === 1) {
    return "-";
  }
  return date.toLocaleString();
};

export const formatToRFC3339 = (date, withOffset = true) => {
  if (!date) {
    return null;
  }
  let dateData = [
    date.getDate(),
    date.getMonth() + 1, // because in js, january is 0, february is 1 ...
    date.getHours(),
    date.getMinutes(),
    date.getSeconds()
  ].map(d => {
    if (d < 10) {
      return `0${d}`;
    }
    return `${d}`;
  });

  let offsetString = "";
  if (withOffset) {
    let offset = date.getTimezoneOffset() / -60;
    const offsetSign = offset >= 0 ? "+" : "-";
    if (offset < 0) {
      offset *= -1;
    }
    offsetString =
      offset === 0 ? `Z` : offset < 10 && offset > -10 ? `${offsetSign}0${offset}:00` : `${offsetSign}${offset}:00`;
  }
  return `${date.getFullYear()}-${dateData[1]}-${dateData[0]}T${dateData[2]}:${dateData[3]}:${
    dateData[4]
  }${offsetString}`;
};

export const formatToDate = date => {
  if (!date) {
    return null;
  }
  let dateData = [
    date.getDate(),
    date.getMonth() + 1 // because in js, january is 0, february is 1 ...
  ].map(d => {
    if (d < 10) {
      return `0${d}`;
    }
    return `${d}`;
  });

  return `${date.getFullYear()}-${dateData[1]}-${dateData[0]}`;
};

export const stringToDate = stringDate => new Date(stringDate);
export const dateToLocaleString = date => formatToRFC3339(date, false);

export const loadGmapScript = () => {
  if (window.google === null) {
    const scriptElement = document.createElement("script");
    scriptElement.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyCPJYY1oePyyQbMwdx5ScUBXja281UwCcs";
    const body = document.getElementsByTagName("body")[0];
    body.append(scriptElement);
  }
};

export const sortById = (a, b) => (a.id < b.id ? -1 : 1);

export const getClusterName = device => {
  if (device?.cluster_name) {
    return device.cluster_name;
  }
  if (device?.cluster?.name) {
    return device.cluster.name;
  }
  return "-";
};

import {
  DEVICE_OUT_OF_SERVICE_STATUS,
  DEVICE_HEALTH_UP,
  DEVICE_HEALTH_DOWN,
  DEVICE_HEALTH_CRITICAL,
  CLUSTER_TYPE_COLOR,
  CLUSTER_STATE_CSS_CLASS
} from "Constants";
export const isDeviceOutOfService = device => {
  return device.status === DEVICE_OUT_OF_SERVICE_STATUS;
};
export const isDeviceOk = device => {
  return device.status != DEVICE_OUT_OF_SERVICE_STATUS && device.health && device.health.status === DEVICE_HEALTH_UP;
};
export const isDeviceDown = device => {
  return device.status != DEVICE_OUT_OF_SERVICE_STATUS && device.health && device.health.status === DEVICE_HEALTH_DOWN;
};
export const isDeviceCritical = device => {
  return (
    device.status != DEVICE_OUT_OF_SERVICE_STATUS && device.health && device.health.status === DEVICE_HEALTH_CRITICAL
  );
};
import { createCluster } from "Api/cluster";
import { createClusterState } from "Api/clusterState";
import { ONBOARDING_1_STATE } from "Constants";
export const createClusterWithState = (apiClient, params) => {
  return createCluster(apiClient)(params)
    .then(({ data }) => {
      return createClusterState(apiClient)(data.id, ONBOARDING_1_STATE, {}).then(() => {
        return {
          data
        };
      });
    })
    .catch(e => this.setError(e));
};
export const isUsingMobileApp = connections => {
  return connections.find(c => c.client === "d4c6bafd-9484-4204-b5fd-637b46606678");
};

import {
  ADMIN_USER_ROLE,
  CARE_USER_ROLE,
  SALE_USER_ROLE,
  USER_ROLE,
  DRIVER_USER_ROLE,
  SEASONAL_DRIVER_USER_ROLE,
  IMPLEMENT_MANAGER_USER_ROLE
} from "Constants";
export const cssClassByUserRole = role => {
  return {
    [CARE_USER_ROLE]: "is-dark",
    [SALE_USER_ROLE]: "is-info",
    [ADMIN_USER_ROLE]: "is-danger",
    [USER_ROLE]: "is-default",
    [DRIVER_USER_ROLE]: "is-success",
    [SEASONAL_DRIVER_USER_ROLE]: "is-success",
    [IMPLEMENT_MANAGER_USER_ROLE]: "is-warning"
  }[role];
};

import { FRONT_URL } from "Constants";
export const openFrontTabWithOauthAccess = token => {
  const url = `${FRONT_URL}/oauth_access_token#access_token=${token}`;
  const a = document.createElement("a");
  a.setAttribute("target", "_blank");
  a.setAttribute("href", url);
  a.click();
};

import axios from "axios";
export const callApiWithSignal = (apiFunc, client) => {
  const signal = axios.CancelToken.source();
  return {
    apiFunc: apiFunc(client, signal.token),
    signal
  };
};

import { BEACON_TYPE_EQUIPMENT, BEACON_TYPE_DRIVER } from "Constants";
export const getBeaconTypeCssClassName = type => {
  if (type === BEACON_TYPE_EQUIPMENT) {
    return "is-info";
  }
  if (type === BEACON_TYPE_DRIVER) {
    return "is-success";
  }
  return null;
};

import { KARNOTT_OUTIL_WITH_FALTY_COMPONENTS_RANGES } from "Constants";
export const isKarnottOutilWithFaultyComponents = uuid => {
  return KARNOTT_OUTIL_WITH_FALTY_COMPONENTS_RANGES.some(range => {
    return uuid >= range.start && uuid <= range.end;
  });
};

export const formatInputDates = (fromDate, toDate) => {
  if (fromDate === null || toDate === null) {
    return { fromDate, toDate };
  }
  if (!(fromDate instanceof Date)) {
    fromDate = stringToDate(fromDate);
  }
  if (!(toDate instanceof Date)) {
    toDate = stringToDate(toDate);
  }
  return { fromDate, toDate };
};

export const getClusterTypeColor = cluster_type => CLUSTER_TYPE_COLOR[cluster_type] || "is-primary";

export const getClusterCurrentStateColor = current_state => CLUSTER_STATE_CSS_CLASS[current_state] || "is-dark";

export const sortClusters = a => (a.current_state ? -1 : 1);
