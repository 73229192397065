<template>
  <b-modal :active="isActive" :on-cancel="() => closeModal(true)" :can-cancel="canCancelOpts">
    <form @submit.prevent="submit">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            Definir la plage de date du plugin [{{ plugin?.option }}] ({{
              plugin?.activated ? "activation" : "desactivation"
            }})
          </p>
        </header>
        <section class="modal-card-body">
          <b-field label="Date de début">
            <b-datepicker
              v-model="fromDate"
              placeholder="Select..."
              icon="calendar-today"
              ref="fromDate"
              @input="() => setFocus('fromDate')"
              :icon-right-clickable="true"
              :icon-right="'close-circle'"
              @icon-right-click="() => (fromDate = null)"
            />
          </b-field>
          <b-field label="Date de fin">
            <b-datepicker
              v-model="toDate"
              placeholder="Select..."
              icon="calendar-today"
              ref="toDate"
              @input="() => setFocus('toDate')"
              :icon-right-clickable="true"
              :icon-right="'close-circle'"
              @icon-right-click="() => (toDate = null)"
            />
          </b-field>
        </section>
      </div>
      <footer class="modal-card-foot">
        <a class="button is-primary" @click.prevent="() => closeModal(true)">Annuler</a>
        <button class="button is-info">Ajouter</button>
      </footer>
    </form>
  </b-modal>
</template>
<style scoped>
.modal-card {
  min-height: 450px;
  width: 100%;
}
</style>
<script>
import { mapActions } from "vuex";
import { createClusterPlugin } from "Api/clusterPlugin";
import { SET_ERROR_ACTION, SET_SUCCESS_ACTION } from "Stores/message";
import { formatToDate } from "Utils";

export default {
  props: ["plugin", "cluster", "isActive", "onClose", "afterPluginUpdate", "onCancel"],
  watch: {
    plugin() {
      this.fromDate = this.plugin?.fromDate ? new Date(this.plugin.fromDate) : null;
      this.toDate = this.plugin?.toDate ? new Date(this.plugin.toDate) : null;
    }
  },
  methods: {
    ...mapActions({
      setError: SET_ERROR_ACTION,
      setSuccess: SET_SUCCESS_ACTION
    }),
    setFocus(ref) {
      // need to set focus on datetimepicker
      // when value is changed
      this.$refs[ref].focus();
    },
    closeModal(cancel) {
      this.fromDate = null;
      this.toDate = null;
      this.onClose();
      cancel && this.onCancel && this.onCancel(this.plugin);
    },
    submit() {
      const { apiClient } = this.$store.getters;
      const { plugin, fromDate, toDate, cluster } = this;
      let data = {
        option: plugin.option,
        activated: plugin.activated,
        from_date: formatToDate(fromDate),
        to_date: formatToDate(toDate)
      };

      createClusterPlugin(apiClient)(cluster.id, data)
        .then(({ data }) => this.afterPluginUpdate && this.afterPluginUpdate(data))
        .catch(e => {
          this.setError({
            message: "Une erreur est survenue",
            error: e
          });
        });
      this.closeModal();
    }
  },
  computed: {
    canCancelOpts() {
      return ["escape", "x", "outside"];
    }
  },
  data() {
    return {
      fromDate: null,
      toDate: null
    };
  }
};
</script>
