<template>
  <div v-if="this.device" class="section">
    <section class="hero is-primary">
      <div class="column is-narrow"></div>
      <h1 class="title">
        <span class="tag is-danger is-large" v-if="isDeviceOutOfService(this.device)">
          <b-icon icon="skull-crossbones" size="is-small"></b-icon>
        </span>
        <span class="tag is-success is-large" v-if="isDeviceOk(this.device)">
          <b-icon icon="thumb-up" size="is-small"></b-icon>
        </span>
        <span class="tag is-warning is-large" v-if="isDeviceDown(this.device)">
          <b-icon icon="alert-circle" size="is-small"></b-icon>
        </span>
        <span class="tag is-danger is-large" v-if="isDeviceCritical(this.device)">
          <b-icon icon="alert" size="is-small"></b-icon>
        </span>
        &nbsp;
        <span class="tag is-large">
          <b-icon :icon="this.getBatteryIcon()" size="is-small"></b-icon>
        </span>
        &nbsp;

        <input v-if="this.editingDeviceName" type="text" class="input editName" v-model="deviceName" />
        <template v-else>
          {{
            this.device.name === this.device.serialnumber
              ? this.device.serialnumber
              : this.device.serialnumber + " [" + this.device.name + "]"
          }}
        </template>

        <span class="tag is-large" v-if="this.device.cluster_id">
          {{ this.getGuaranteeLabel() }}
        </span>
        &nbsp;
        <span class="tag is-large" v-if="this.device.cluster_id">
          {{ this.device.cluster_state }}
        </span>

        <div class="buttons">
          <b-button
            icon-left="check-outline"
            v-if="this.editingDeviceName"
            class="button is-info"
            @click="updateDeviceName"
            :disabled="!this.device.name"
          >
          </b-button>
          <b-button
            v-else
            class="button is-info"
            @click.prevent="switchOnDeviceNameEditing()"
            icon-left="pencil"
          ></b-button>
          <b-button
            type="is-warning"
            icon-left="upload"
            @click.prevent="showImportDataFileModal()"
            v-if="currentUser && currentUser.isAdmin()"
          >
            Importer des données
          </b-button>
        </div>
      </h1>
      <div class="hero-body">
        <div class="columns is-multiline">
          <div class="column field is-one-third" v-if="currentUser && !currentUser.isHardware()">
            <div class="columns is-vcentered">
              <div class="column is-narrow">
                <label class="label">Exploitation :</label>
              </div>
              <div class="column" style="padding-left: 0">
                <span class="device-current-cluster">
                  <div class="tags has-addons">
                    <router-link
                      :to="'/cluster/' + this.device.cluster_id"
                      class="tag current-cluster-name"
                      target="_blank"
                    >
                      {{ this.currentClusterName }}
                    </router-link>

                    <div
                      class="tag is-success button"
                      v-if="device.status !== 'MOUNTED'"
                      @click="() => (showLinkDeviceToClusterModal = true)"
                    >
                      Modifier
                    </div>
                    <div class="tag is-danger" v-if="device.status === 'MOUNTED' && !device.cluster_id">
                      Avec le statut MOUNTED, un karnott ne peut être lié à une exploitation
                    </div>
                    <div
                      v-if="device.cluster_id && device.cluster_id > 0"
                      class="tag is-warning button"
                      @click="() => (showUpdateAssociateDateModal = true)"
                    >
                      Modifier la date d'association
                    </div>
                    <div
                      v-if="device.cluster_id && device.cluster_id > 0"
                      class="tag button is-danger"
                      @click.prevent="unlinkKarnott()"
                    >
                      <b-icon icon="link-off" size="is-small"></b-icon>
                    </div>
                    <UnlinkDeviceTwoDaysLaterButton
                      v-if="device.cluster_id && device.cluster_id > 0"
                      :cluster="currentCluster"
                      :device="device"
                      cssClass="tag"
                    />
                  </div>
                </span>
              </div>
            </div>
          </div>
          <div class="column is-one-third">
            <div class="columns is-vcentered" v-if="!!currentDeviceHasCluster?.cluster_id">
              <div class="column is-narrow">
                <label class="label"> Statut de liaison avec l'exploitation :</label>
                <b-select
                  :loading="changeStateLoading"
                  @input="updateDeviceHasClusterState()"
                  v-model="currentDeviceHasCluster.state"
                >
                  <option
                    v-bind:key="option"
                    v-for="option in DEVICE_STATES_WITH_CLUSTER"
                    :value="option"
                    :disabled="option === UNDEFINED_DEVICE_STATE_WITH_CLUSTER"
                  >
                    {{ fmtDeviceStateWithCluster(option) }}
                  </option>
                </b-select>
              </div>
            </div>
          </div>
          <div class="column is-one-third">
            <div class="columns is-vcentered">
              <div class="column is-narrow">
                <label class="label">Statut :</label>
              </div>
              <div class="column" style="padding-left: 0">
                <span class="device-status">{{ this.device.status }}</span>
              </div>
            </div>
          </div>
          <div v-if="!device.cluster_id || device.cluster_id === 0" class="column field is-one-third">
            <div class="columns is-vcentered">
              <div class="column is-narrow">
                <label class="label">Maintenance :</label>
              </div>
              <div class="column" style="padding-left: 0">
                <b-switch v-model="isMaintenance" @input="switchMaintenance" type="is-warning">
                  {{ isMaintenance ? "Oui" : "Non" }}
                </b-switch>
              </div>
            </div>
          </div>
          <div class="column field is-one-third">
            <div class="columns is-vcentered">
              <div class="column is-narrow">
                <label class="label">MAC :</label>
              </div>
              <div class="column" style="padding-left: 0">
                <span class="mac-address">{{ this.device.noolitic_mac }}</span>
              </div>
            </div>
          </div>
          <div class="column field is-one-third">
            <div class="columns is-vcentered">
              <div class="column is-narrow">
                <label class="label">SIM ICCID :</label>
              </div>
              <div class="column" style="padding-left: 0">
                <span class="sim_iccid">{{ this.device.sim_iccid ? this.device.sim_iccid : "-" }}</span>
              </div>
            </div>
          </div>
          <div class="column field is-one-third">
            <div class="columns is-vcentered">
              <div class="column is-narrow">
                <label class="label">Type / Version BLE :</label>
              </div>
              <div class="column" style="padding-left: 0">
                <span class="hardware"
                  >{{ this.device.device_model ? this.device.device_model.label : "-" }} /
                  {{ this.device.ble_version ? this.device.ble_version : "-" }}
                </span>
              </div>
            </div>
          </div>
          <div class="column field is-one-third">
            <div class="columns is-vcentered">
              <div class="column is-narrow">
                <label class="label">Firmware :</label>
              </div>
              <div class="column" style="padding-left: 0">
                <span class="firmware-version"
                  >{{ this.device.firmware_name ? this.device.firmware_name : "-" }} ({{
                    this.device.firmware_version ? this.device.firmware_version : "-"
                  }})</span
                >
              </div>
            </div>
          </div>
          <div class="column field is-one-third">
            <div class="columns is-vcentered">
              <div class="column is-narrow">
                <label class="label">Première connexion du firmware :</label>
              </div>
              <div class="column" style="padding-left: 0">
                <span class="firmware_first_date">{{
                  this.device.firmware_first_date
                    ? new Date(Date.parse(this.device.firmware_first_date)).toLocaleString()
                    : "-"
                }}</span>
              </div>
            </div>
          </div>
          <div class="column field is-one-third">
            <div class="columns is-vcentered">
              <div class="column is-narrow">
                <label class="label">Batterie ((Etat de santé (SOH) / SOC recalculé via la tension) :</label>
              </div>
              <div class="column" style="padding-left: 0">
                <span class="external_battery_level"
                  >{{ this.device.battery_level }} / {{ this.device.external_battery_level }}</span
                >
              </div>
            </div>
          </div>
          <div class="column field is-one-third">
            <div class="columns is-vcentered">
              <div class="column is-narrow">
                <label class="label">Dernière connexion :</label>
              </div>
              <div class="column" style="padding-left: 0">
                <span class="last_connection_date">{{
                  this.device.last_connection_date
                    ? new Date(Date.parse(this.device.last_connection_date)).toLocaleString()
                    : "-"
                }}</span>
              </div>
            </div>
          </div>
          <div class="column field is-one-third">
            <div class="columns is-vcentered">
              <div class="column is-narrow">
                <label class="label">Dernière information :</label>
              </div>
              <div class="column" style="padding-left: 0">
                <span class="lastinfo_date">{{
                  this.device.lastinfo_date ? new Date(Date.parse(this.device.lastinfo_date)).toLocaleString() : "-"
                }}</span>
              </div>
            </div>
          </div>
          <div class="column field is-one-third">
            <div class="columns is-vcentered">
              <div class="column is-narrow">
                <label class="label">Version Hardware / Version Batterie:</label>
              </div>
              <div class="column" style="padding-left: 0">
                <span class="hardware">
                  {{ this.device.hardware_version ? this.device.hardware_version : "-" }} /
                  {{
                    this.device.batteryHardwareName
                      ? `${this.device.batteryHardwareName} (id ${this.device.battery_hardware_id})`
                      : "-"
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="columns">
            <div class="column is-three-quarters field">
              <div class="message update-status-message is-info">
                <div class="message-body">
                  <b-field>
                    <div class="column">
                      <label class="label">Passer le statut du Karnott à :&nbsp;</label>
                      <button
                        class="button is-success is-small"
                        @click="() => updateStatus(AVAILABLE)"
                        v-if="device.status !== AVAILABLE"
                      >
                        Disponible
                      </button>
                      &nbsp;
                      <button
                        class="button is-danger is-small"
                        @click="() => updateStatus(TO_ANALYZE)"
                        v-if="device.status !== TO_ANALYZE"
                      >
                        A analyser
                      </button>
                      &nbsp;
                      <button
                        v-if="currentUser && device.status !== MOUNTED"
                        class="button is-warning is-small"
                        @click="() => updateStatus(MOUNTED)"
                      >
                        À valider
                      </button>
                      &nbsp;
                      <button
                        v-if="currentUser && device.status !== STOCK && !currentDeviceHasCluster?.cluster_id"
                        class="button is-info is-small"
                        @click="() => updateStatus(STOCK)"
                      >
                        En stock
                      </button>
                      &nbsp;
                      <button
                        v-if="currentUser && device.status !== STOCK_REHAB && !currentDeviceHasCluster?.cluster_id"
                        class="button is-info is-small"
                        @click="() => updateStatus(STOCK_REHAB)"
                      >
                        En stock REHAB
                      </button>
                      &nbsp;
                      <button
                        v-if="currentUser && device.status !== STOCK_LENDING && !currentDeviceHasCluster?.cluster_id"
                        class="button is-info is-small"
                        @click="() => updateStatus(STOCK_LENDING)"
                      >
                        En stock de prêt
                      </button>
                      &nbsp;
                      <button
                        v-if="
                          currentUser &&
                          device.status !== STOCK_PENDING_RETURN &&
                          (!currentDeviceHasCluster?.cluster_id ||
                            (currentDeviceHasCluster.cluster_id && currentDeviceHasCluster.state === 'lending'))
                        "
                        class="button is-info is-small"
                        @click="() => updateStatus(STOCK_PENDING_RETURN)"
                      >
                        En attente de retour en stock
                      </button>
                      &nbsp;
                      <button
                        class="button is-danger is-small"
                        style="margin-left: 80px"
                        @click="() => updateStatus(DEVICE_OUT_OF_SERVICE)"
                        v-if="device.status !== DEVICE_OUT_OF_SERVICE"
                      >
                        Hors Service
                      </button>
                    </div>
                  </b-field>
                </div>
              </div>
            </div>
            <div class="column field" v-if="device.health.battery.status !== DEVICE_BATTERY_STATUS_OK">
              <div class="message update-status-message is-info">
                <div class="message-body">
                  <b-field>
                    <div class="column">
                      <label class="label">Reset le statut batterie :&nbsp;</label>
                      <button
                        class="button is-success is-small"
                        @click="() => updateBatteryStatus(DEVICE_BATTERY_STATUS_OK)"
                      >
                        OK
                      </button>
                    </div>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="hero-foot">
        <nav class="tabs is-boxed is-fullwidth is-centered">
          <ul>
            <li @click="showTab(DeviceDataTab)" :class="isTabActive(DeviceDataTab)">
              <a>Liste des data</a>
            </li>
            <li
              @click="showTab(ClustersHistoryTab)"
              :class="isTabActive(ClustersHistoryTab)"
              v-if="currentUser && !currentUser.isHardware()"
            >
              <a>Historique des exploitations</a>
            </li>
            <li
              @click="showTab(DeviceInstanceStatusHistoryTab)"
              :class="isTabActive(DeviceInstanceStatusHistoryTab)"
              v-if="currentUser && !currentUser.isHardware()"
            >
              <a>
                Historique des status &nbsp;<span
                  class="tag is-warning is-small"
                  v-if="statusHistory.filter(h => h.status === TO_ANALYZE).length > 2"
                  >{{ statusHistory.filter(h => h.status === TO_ANALYZE).length }}</span
                >
              </a>
            </li>
            <li
              @click="showTab(EquipmentHistoryTab)"
              :class="isTabActive(EquipmentHistoryTab)"
              v-if="currentUser && !currentUser.isHardware()"
            >
              <a>Historique des équipements</a>
            </li>
            <li @click="showTab(SessionsItemTab)" :class="isTabActive(SessionsItemTab)">
              <a>Liste des sessions</a>
            </li>
            <li @click="showTab(EventPointsTab)" :class="isTabActive(EventPointsTab)">
              <a>Carte des points GPS du boitier</a>
            </li>
            <li @click="showTab(DeviceValidationTab)" :class="isTabActive(DeviceValidationTab)">
              <a>Validations du boîtier</a>
            </li>
            <li @click="showTab(DeviceConfTab)" :class="isTabActive(DeviceConfTab)">
              <a>Configuration du boîtier</a>
            </li>
            <li @click="showTab(DeviceAnalysisTab)" :class="isTabActive(DeviceAnalysisTab)">
              <a>Analyses</a>
            </li>
            <li @click="showTab(DeviceDiagnosticTab)" :class="isTabActive(DeviceDiagnosticTab)">
              <a>Diagnostic</a>
            </li>
            <li @click="showTab(DeviceMonitoringTab)" :class="isTabActive(DeviceMonitoringTab)">
              <a>
                Monitoring &nbsp;<span
                  class="tag is-success is-small"
                  v-if="deviceMonitoring.filter(d => d.severity === 'WARNING').length > 0"
                  >{{ deviceMonitoring.filter(d => d.severity === "WARNING").length }}</span
                >
                &nbsp;<span
                  class="tag is-warning is-small"
                  v-if="deviceMonitoring.filter(d => d.severity === 'ERROR').length > 0"
                  >{{ deviceMonitoring.filter(d => d.severity === "ERROR").length }}</span
                >
                &nbsp;<span
                  class="tag is-danger is-small"
                  v-if="deviceMonitoring.filter(d => d.severity === 'CRITICAL').length > 0"
                  >{{ deviceMonitoring.filter(d => d.severity === "CRITICAL").length }}</span
                >
              </a>
            </li>
            <li @click="showTab(HubspotTicketsTab)" :class="isTabActive(HubspotTicketsTab)">
              <a>Liste des tickets hubspot ({{ hubspotTickets.length }}) </a>
            </li>
          </ul>
        </nav>
      </div>
    </section>

    <section>
      <div class="has-background-white">
        <UpdateDeviceAssociateDateModal
          :device="device"
          :currentDate="device.associate_date"
          :isActive="showUpdateAssociateDateModal"
          :closeModalAction="closeUpdateDeviceAssociateDateModal"
        />
        <LinkDeviceToClusterModal
          :device="device"
          :isActive="showLinkDeviceToClusterModal"
          :closeModalAction="closeLinkDeviceToClusterModal"
        />
        <DeviceInstanceStatusHistoryTabItem
          :device="device"
          :statusHistory="statusHistory"
          v-if="currentTab === DeviceInstanceStatusHistoryTab"
        />
        <ImportDataFileModal
          :device="device"
          :isActive="isImportDataFileModalActive"
          :onClose="closeImportDataFileModal"
        />
        <DeviceDataTabItem :device="device" v-if="currentTab === DeviceDataTab" />
        <DeviceClustersHistoryTabItem
          :device="device"
          v-if="currentUser && !currentUser.isHardware() && currentTab === ClustersHistoryTab"
          :onCurrentDeviceHasClusterStateChange="reloadDeviceHasCluster"
        />
        <EquipmentDeviceHistoryTabItem
          :device="device"
          v-if="currentUser && !currentUser.isHardware() && currentTab === EquipmentHistoryTab"
        />
        <DeviceEventPointsTabItem :device="device" v-if="currentTab === EventPointsTab" />
        <DeviceConfTabItem :device="device" v-if="currentTab === DeviceConfTab" />
        <DeviceAnalysisTabItem :device="device" v-if="currentTab === DeviceAnalysisTab" />
        <DeviceDiagnosticTabItem :device="device" v-if="currentTab === DeviceDiagnosticTab" />
        <DeviceValidationTabItem :device="device" v-if="currentTab === DeviceValidationTab" />
        <SessionsTabItem :device="device" v-if="currentTab === SessionsItemTab" />
        <DeviceMonitoringTabItem
          :propDevice="device"
          :propDeviceMonitoring="deviceMonitoring"
          :propUpdateDeviceMonitoring="updateDeviceMonitoring"
          v-if="currentTab === DeviceMonitoringTab"
        />
        <HubspotTicketsTabItem
          :device="device"
          :hubspotTickets="hubspotTickets"
          v-if="currentTab === HubspotTicketsTab"
        />
      </div>
    </section>
  </div>
</template>
<script>
import { getClusterById } from "Api/cluster";
import {
  switchMaintenance,
  changeStatus,
  updateDeviceName,
  getDeviceInstanceStatusHistory,
  getHubspotTickets,
  getDeviceMonitoring,
  updateBatteryStatus
} from "Api/device";
import { unLinkDeviceToCluster, updateDeviceHasClusterState, getDeviceHasClusterHistory } from "Api/deviceHasCluster";
import { BATTERY_HARDWARE_ID, GET_DEVICE_BY_ID_ACTION } from "Stores/devices";
import { GET_USER_ME_ACTION } from "Stores/users";
import { SET_ERROR_ACTION, SET_SUCCESS_ACTION } from "Stores/message";
import DeviceDataTabItem from "Components/devices/DeviceDataTabItem";
import DeviceConfTabItem from "Components/devices/DeviceConfTabItem";
import DeviceAnalysisTabItem from "Components/devices/DeviceAnalysisTabItem";
import DeviceDiagnosticTabItem from "Components/devices/DeviceDiagnosticTabItem";
import DeviceMonitoringTabItem from "Components/devices/DeviceMonitoringTabItem";
import DeviceValidationTabItem from "Components/devices/DeviceValidationTabItem";
import DeviceClustersHistoryTabItem from "Components/devices/DeviceClustersHistoryTabItem";
import LinkDeviceToClusterModal from "Components/devices/LinkDeviceToClusterModal";
import UpdateDeviceAssociateDateModal from "Components/devices/UpdateDeviceAssociateDateModal";
import EquipmentDeviceHistoryTabItem from "Components/devices/EquipmentDeviceHistoryTabItem";
import DeviceEventPointsTabItem from "Components/devices/DeviceEventPointsTabItem";
import SessionsTabItem from "Components/devices/SessionsTabItem";
import HubspotTicketsTabItem from "Components/devices/HubspotTicketsTabItem";
import DeviceInstanceStatusHistoryTabItem from "Components/devices/DeviceInstanceStatusHistoryTabItem";
import ImportDataFileModal from "Components/devices/ImportDataFileModal";
import UnlinkDeviceTwoDaysLaterButton from "Components/devices/UnlinkDeviceTwoDaysLaterButton.vue";
import { isDeviceOk, isDeviceDown, isDeviceCritical, isDeviceOutOfService } from "Utils";
import { mapActions, mapGetters } from "vuex";
import {
  UNDEFINED_DEVICE_STATE_WITH_CLUSTER,
  DEVICE_STATES_WITH_CLUSTER,
  DEVICE_STATES_WITH_CLUSTER_LABEL,
  DEVICE_STOCK_STATUS,
  DEVICE_STOCK_REHAB_STATUS,
  DEVICE_STOCK_LENDING_STATUS,
  DEVICE_STOCK_PENDING_RETURN_STATUS,
  DEVICE_OUT_OF_SERVICE_STATUS,
  DEVICE_AVAILABLE_STATUS,
  DEVICE_MOUNTED_STATUS,
  DEVICE_TO_ANALYZE_STATUS,
  DEVICE_BATTERY_STATUS
} from "Constants";
import { formatDate } from "Utils";

const EventPointsTab = "EventPointsTab";
const EquipmentHistoryTab = "EquipmentHistoryTab";
const DeviceDataTab = "DeviceDataTab";
const DeviceConfTab = "DeviceConfTab";
const DeviceAnalysisTab = "DeviceAnalysisTabItem";
const DeviceDiagnosticTab = "DeviceDiagnosticTabItem";
const DeviceMonitoringTab = "DeviceMonitoringTabItem";
const DeviceValidationTab = "DeviceValidationTab";
const ClustersHistoryTab = "ClustersHistoryTab";
const SessionsItemTab = "SessionsItemTab";
const HubspotTicketsTab = "HubspotTicketsTab";
const DeviceInstanceStatusHistoryTab = "DeviceInstanceStatusHistoryTab";

const fmtError = error => message => ({ error, message });
const fmtErrorMessage = entity => `Can't retrieve the ${entity}.<br />Please contact the support.<br />`;

export default {
  mounted() {
    if (this.$route.query.currentTab) {
      this.currentTab = this.$route.query.currentTab;
    }
    const { apiClient } = this.$store.getters;

    Promise.all([
      this.getCurrentUser(),
      this.getDevice(this.$route.params.id),
      this.reloadDeviceHasCluster(),
      getHubspotTickets(apiClient)(this.$route.params.id),
      getDeviceMonitoring(apiClient)({ device_instance_id: [this.$route.params.id] }),
      getDeviceInstanceStatusHistory(apiClient)(this.$route.params.id)
    ])
      .then(res => {
        this.currentUser = res[0];
        const { data } = res[1];
        const { data: hubspotTickets } = res[3];
        this.hubspotTickets = hubspotTickets ? hubspotTickets : [];
        const { data: deviceMonitoring } = res[4];
        this.deviceMonitoring = deviceMonitoring ? deviceMonitoring : [];
        this.deviceMonitoring.sort((a, b) => {
          return new Date(b.occurred_at) - new Date(a.occurred_at);
        });
        const { data: statusHistory } = res[5];
        this.statusHistory = statusHistory ? statusHistory : [];
        this.device = data;
        this.device.batteryHardwareName = BATTERY_HARDWARE_ID[this.device.battery_hardware_id]
          ? BATTERY_HARDWARE_ID[this.device.battery_hardware_id]
          : this.device.battery_hardware_id;
        document.title = data.name;
        const { cluster_id } = this.device;
        if (this.currentUser && this.currentUser.isHardware()) {
          this.currentTab = DeviceValidationTab;
        } else {
          if (!this.currentTab) {
            this.currentTab = EventPointsTab;
          }
        }
        if (this.currentUser.isHardware() || cluster_id === undefined || cluster_id === 0) {
          return { data: {} };
        }
        if (data.associate_date && data.associate_date !== "") {
          this.device.associate_date = new Date(data.associate_date);
        }
        const { apiClient } = this.$store.getters;
        return getClusterById(apiClient)(cluster_id);
      })
      .then(({ data }) => (this.currentCluster = data))
      .then(this.updateUrl)
      .catch(e => this.setError(fmtError(e)(fmtErrorMessage("device"))));
  },
  data() {
    return {
      currentUser: null,
      device: null,
      hubspotTickets: [],
      statusHistory: [],
      deviceMonitoring: [],
      currentCluster: {},
      showLinkDeviceToClusterModal: false,
      showUpdateAssociateDateModal: false,
      currentTab: null,
      // need to set constant in data {}
      // because template can't read constant vars
      EventPointsTab: EventPointsTab,
      EquipmentHistoryTab: EquipmentHistoryTab,
      DeviceConfTab: DeviceConfTab,
      DeviceAnalysisTab: DeviceAnalysisTab,
      DeviceDiagnosticTab: DeviceDiagnosticTab,
      DeviceMonitoringTab: DeviceMonitoringTab,
      DeviceValidationTab: DeviceValidationTab,
      DeviceDataTab: DeviceDataTab,
      ClustersHistoryTab: ClustersHistoryTab,
      SessionsItemTab: SessionsItemTab,
      HubspotTicketsTab: HubspotTicketsTab,
      DeviceInstanceStatusHistoryTab: DeviceInstanceStatusHistoryTab,
      AVAILABLE: DEVICE_AVAILABLE_STATUS,
      TO_ANALYZE: DEVICE_TO_ANALYZE_STATUS,
      MOUNTED: DEVICE_MOUNTED_STATUS,
      STOCK: DEVICE_STOCK_STATUS,
      STOCK_REHAB: DEVICE_STOCK_REHAB_STATUS,
      STOCK_LENDING: DEVICE_STOCK_LENDING_STATUS,
      STOCK_PENDING_RETURN: DEVICE_STOCK_PENDING_RETURN_STATUS,
      DEVICE_OUT_OF_SERVICE: DEVICE_OUT_OF_SERVICE_STATUS,
      DEVICE_BATTERY_STATUS_OK: DEVICE_BATTERY_STATUS.OK,
      DEVICE_BATTERY_STATUS_CRITICAL: DEVICE_BATTERY_STATUS.CRITICAL,
      isImportDataFileModalActive: false,
      editingDeviceName: false,
      deviceName: "",
      UNDEFINED_DEVICE_STATE_WITH_CLUSTER,
      DEVICE_STATES_WITH_CLUSTER,
      changeStateLoading: false,
      currentDeviceHasCluster: {}
    };
  },
  computed: {
    isMaintenance() {
      return this.device && this.device.status === "MAINTENANCE";
    },
    currentClusterName() {
      const { currentCluster } = this;
      if (currentCluster === undefined) {
        return "-";
      }
      const { name } = currentCluster;
      if (name === undefined) {
        return "-";
      }
      return name;
    }
  },
  methods: {
    fmtDeviceStateWithCluster(state) {
      return DEVICE_STATES_WITH_CLUSTER_LABEL[state];
    },
    updateDeviceHasClusterState() {
      const { apiClient } = this.$store.getters;
      const { id, state } = this.currentDeviceHasCluster;
      this.changeStateLoading = true;
      updateDeviceHasClusterState(apiClient)(id, state)
        .then(() => {
          this.changeStateLoading = false;
          this.device = {
            ...this.device,
            ...{ current_state_with_cluster: state }
          };
          this.setSuccess({
            message: "Le statut du device a été mis à jour"
          });
        })
        .catch(e => {
          this.changeStateLoading = false;
          this.setError({
            message: `Une erreur est survenue`,
            error: e
          });
        });
    },
    reloadDeviceHasCluster() {
      const { apiClient } = this.$store.getters;
      const getDeviceHasClusters = getDeviceHasClusterHistory(apiClient);
      const { id } = this?.$route?.params || {};
      return getDeviceHasClusters(id)
        .then(response => {
          const { data: deviceHasClusters } = response;
          if (deviceHasClusters?.length) {
            this.currentDeviceHasCluster = deviceHasClusters.find(
              // ou dhc.to_date > now()
              dhc => !dhc.to_date || dhc.to_date === "0001-01-01T00:00:00Z" || new Date(dhc.to_date) > new Date()
            );
          }
        })
        .catch(e => {
          this.setError({
            message: `Une erreur est survenue en essayant de definir l'exploitation courante`,
            error: e
          });
        });
    },
    getGuaranteeLabel() {
      let dateText = (() => {
        if (this.device.warranty_date) {
          let date = new Date(this.device.warranty_date);
          if (date > new Date()) {
            return "(Garantie jusqu'au " + formatDate(date) + ")";
          } else {
            return "(Garantie expirée le " + formatDate(date) + ")";
          }
        }
        return "";
      })();
      switch (this.device.guarantee_status) {
        case "UNDER_WARRANTY":
          return "Sous garantie " + dateText;
        case "NO_WARRANTY":
          return "Pas/Plus garantie " + dateText;
        default:
          return "Garantie inconnue";
      }
    },
    getBatteryIcon() {
      if (!this.device.external_battery_level) {
        return "battery-off-outline";
      }
      if (this.device.charging_state === "CHARGED") {
        return "power-plug-battery";
      }
      if (this.device.charging_state === "CHARGING") {
        return `battery-charging-${Math.ceil(this.device.external_battery_level / 10) * 10}`;
      }

      if (this.device.external_battery_level > 90) {
        return `battery`;
      }
      return `battery-${Math.ceil(this.device.external_battery_level / 10) * 10}`;
    },
    isDeviceOutOfService(device) {
      return isDeviceOutOfService(device);
    },
    isDeviceOk(device) {
      return isDeviceOk(device);
    },
    isDeviceDown(device) {
      return isDeviceDown(device);
    },
    isDeviceCritical(device) {
      return isDeviceCritical(device);
    },
    updateStatus(newStatus) {
      var message = `Etes vous sur de vouloir passer le karnott ${this.device.serialnumber} en ${newStatus} ?`;
      var dessaciateDevice = false;
      if (newStatus === this.STOCK_PENDING_RETURN && this.currentDeviceHasCluster) {
        message = `Etes vous sur de vouloir passer le karnott ${this.device.serialnumber} en ${newStatus} ? (Il sera automatiquement désassocié maintenant de l'exploitation)`;
        dessaciateDevice = true;
      }
      this.$buefy.dialog.confirm({
        message: message,
        onConfirm: () => {
          const { apiClient } = this.$store.getters;
          const { device } = this;
          if (device.status === newStatus) {
            this.setError({
              message: `Le statut est déjà ${newStatus}`
            });
            return;
          }
          const params = { deviceInstanceIds: [device.id], status: newStatus };
          changeStatus(apiClient)(params)
            .then(() => {
              if (dessaciateDevice) {
                unLinkDeviceToCluster(apiClient)(device.id, device.cluster_id)
                  .then(() => {
                    this.currentDeviceHasCluster = {};
                    this.setSuccess({
                      message: "Le status a bien été mis à jour et le karnott a été désassocié de l'exploitation"
                    });
                    this.device = {
                      ...this.device,
                      ...{ cluster_id: 0 },
                      status: newStatus
                    };
                    this.currentCluster = {};
                  })
                  .catch(e => {
                    this.setError({
                      message: `Une erreur est survenue`,
                      error: e
                    });
                  });
              } else {
                this.setSuccess({
                  message: "Le statut a bien été mis à jour"
                });
                this.device = {
                  ...this.device,
                  status: newStatus
                };
              }
            })
            .catch(e => {
              this.setError({
                message: `Une erreur est survenue`,
                error: e
              });
            });
        }
      });
    },
    updateBatteryStatus(batteryStatus) {
      this.$buefy.dialog.confirm({
        message: `Etes vous sur de vouloir passer le statut de la batterie du karnott ${this.device.serialnumber} en ${batteryStatus} ?`,
        onConfirm: () => {
          const { apiClient } = this.$store.getters;
          const { device } = this;
          if (device.health.battery.status === batteryStatus) {
            this.setError({
              message: `Le statut de la batterie est déjà ${batteryStatus}`
            });
            return;
          }
          const params = { deviceInstanceId: device.id, batteryStatus };
          updateBatteryStatus(apiClient)(params)
            .then(response => {
              this.setSuccess({
                message: "Le statut de la batterie a bien été mis à jour"
              });
              this.device = response.data;
            })
            .catch(e => {
              this.setError({
                message: `Une erreur est survenue`,
                error: e
              });
            });
        }
      });
    },
    switchMaintenance(status) {
      const { apiClient } = this.$store.getters;
      return switchMaintenance(apiClient)(this.device.id)(status).then(({ data }) => (this.device.status = data));
    },
    closeUpdateDeviceAssociateDateModal(newDate) {
      if (newDate) {
        this.device = {
          ...this.device,
          ...{ associate_date: newDate }
        };

        this.showTab(ClustersHistoryTab);
      }
      this.showUpdateAssociateDateModal = false;
    },
    closeLinkDeviceToClusterModal(cluster) {
      if (cluster && cluster.id !== undefined) {
        this.setNewCurrentCluster(cluster);
      }
      this.showLinkDeviceToClusterModal = false;
    },
    setNewCurrentCluster(cluster) {
      this.currentCluster = cluster;
      this.device = {
        ...this.device,
        ...{ cluster_id: cluster.id, status: DEVICE_AVAILABLE_STATUS }
      };
      this.reloadDeviceHasCluster();
      this.showTab(ClustersHistoryTab);
    },
    isTabActive(title) {
      return {
        "is-active": title === this.currentTab
      };
    },
    showTab(title) {
      this.currentTab = title;
      this.updateUrl();
    },
    updateUrl() {
      const query = Object.assign({}, this.$route.query);
      query.currentTab = this.currentTab;
      this.$router.push({ path: this.device.id.toString(), query }).catch(() => {});
    },
    unlinkKarnott() {
      this.$buefy.dialog.confirm({
        message: "Etes vous sur ?",
        onConfirm: () => {
          const { apiClient } = this.$store.getters;
          const { device } = this;
          unLinkDeviceToCluster(apiClient)(device.id, device.cluster_id)
            .then(() => {
              this.currentDeviceHasCluster = {};
              this.setSuccess({
                message: "L'exploitation n'est plus liée au Karnott"
              });
              this.device = {
                ...this.device,
                ...{ cluster_id: 0 }
              };
              this.currentCluster = {};
            })
            .catch(e => {
              this.setError({
                message: `Une erreur est survenue`,
                error: e
              });
            });
        }
      });
    },
    showImportDataFileModal() {
      this.isImportDataFileModalActive = true;
    },
    closeImportDataFileModal() {
      this.isImportDataFileModalActive = false;
    },
    switchOnDeviceNameEditing() {
      this.deviceName = this.device.name;
      this.editingDeviceName = true;
    },
    switchOffDeviceNameEditing() {
      this.editingDeviceName = false;
      this.device.name = this.deviceName;
    },
    updateDeviceMonitoring(dm) {
      this.deviceMonitoring = dm;
    },
    updateDeviceName() {
      const { apiClient } = this.$store.getters;
      if (!this.deviceName) {
        this.deviceName = this.device.serialnumber;
      }
      updateDeviceName(apiClient)(this.device.id, this.deviceName)
        .then(this.switchOffDeviceNameEditing)
        .catch(e => this.setError(fmtError(e)(fmtErrorMessage("device"))));
    },
    ...mapActions({
      getDevice: GET_DEVICE_BY_ID_ACTION,
      getCurrentUser: GET_USER_ME_ACTION,
      setError: SET_ERROR_ACTION,
      setSuccess: SET_SUCCESS_ACTION
    }),
    ...mapGetters(["getHostname"])
  },
  components: {
    DeviceAnalysisTabItem,
    DeviceClustersHistoryTabItem,
    DeviceConfTabItem,
    DeviceDataTabItem,
    DeviceDiagnosticTabItem,
    DeviceEventPointsTabItem,
    DeviceInstanceStatusHistoryTabItem,
    DeviceMonitoringTabItem,
    DeviceValidationTabItem,
    EquipmentDeviceHistoryTabItem,
    HubspotTicketsTabItem,
    ImportDataFileModal,
    LinkDeviceToClusterModal,
    SessionsTabItem,
    UnlinkDeviceTwoDaysLaterButton,
    UpdateDeviceAssociateDateModal
  }
};
</script>

<style scoped>
.tabs {
  font-size: 14px !important;
}
.title {
  padding-left: 0.5em;
}
.hero {
  padding: 1.5em 1em 0;
}
.hero .column {
  padding: 0.5em 1em;
}
.hero .column .label {
  padding-bottom: 0.1em;
  margin-bottom: 0;
  color: #d3d3d3;
}

.current-cluster-name {
  color: white;
  background: #3c2565;
}
.update-status-message label {
  color: #4a4a4a !important;
}
.editName {
  max-width: 400px;
  margin-left: 0.5em;
}
</style>
